:root {
    --primary-color: #2C3E50; /* Deep Blue */
    --secondary-color: #F1C40F; /* Soft Gold */
    --accent-color: #1ABC9C; /* Teal */
    --background-color: #ECF0F1; /* Light Gray */
    --text-color: #34495E; /* Dark Slate Gray */
    --response-background-color: #E0F7F4; /* Light Teal */
}

/* Font face declaration */
@font-face {
    font-family: 'VTCMarsha-Bold';
    src: url('./fonts/VTCMarsha-Bold.woff') format('woff');
    font-style: normal;
}

.top-header {
    text-align: left;
}

.chat-container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
    text-align: left;
    background-color: #fff;
    border: 1px solid #48cdfe;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: 100%; /* Default width */
    max-height: 100vh; /* Set max height to viewport height */

    display: flex;
    flex-direction: column;
    transition: width 0.3s ease, max-height 0.3s ease; /* Smooth transition */
    overflow: hidden; /* Prevent overflow issues */
}

.chat-toggle-button {
    width: 100%;
    background: var(--primary-color);
    color: white;
    padding: 10px;
}

.chat-toggle-button {
    animation: gradientAnimation 4s ease infinite;
    background: linear-gradient(135deg, #06598d, #f34d10);
    height: 50px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
    margin: 0;
    text-align: center;
    width: 100%;
}

.chat-popup {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 25px;
    padding: 10px; /* Add padding inside the chat popup */
    position: relative;
    background-color: #fff;
    overflow: hidden; /* Hide overflow to prevent unwanted scrollbars */
    border-radius: 0 0 8px 8px;
    width: 100%; /* Ensure it takes the width of the container */
    box-sizing: border-box; /* Ensure padding is included in the width calculation */
    flex-basis: 100%; /* Allow the chat-popup to grow */
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(56, 54, 54, 0.7);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1000;
    display: none;
}

.loading-overlay.visible {
    display: flex;
}

.loading-indicator {
    text-align: center;
    font-size: .9em;
    font-weight: bold;
    color: white;
    background-color: transparent;
    animation: blinker 2s linear infinite;
    margin-bottom: 65px;
}

.response-container {
    flex-grow: 1;
    overflow-y: auto; /* Enable vertical scrolling */
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    padding: 5px;
    background-color: var(--background-color);
    box-sizing: border-box;
    height: 100%; /* Adjust to ensure the container height is 100% */
    width: 100%;
}

.response-container::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

.response-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.response-container::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 10px;
}

.response-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

textarea {
    padding: 10px;
    width: calc(100% - 20px); /* Full width minus padding/margin */
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: both; /* Allow both horizontal and vertical resizing */
    overflow: auto; /* Ensure overflow is handled */
    min-height: 50px; /* Minimum height */
    max-height: calc(20 * 1.5em); /* Maximum height for 20 rows, assuming 1.5em line height */
}

.button-container {
    display: flex;
    justify-content: space-between;
}

button[type="submit"], button[title="Clear Chat"], button[title="Copy Chat"] {
    background-color: #48cdfe;
    color: black;
    font-weight: bold;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    margin: 5px;
    box-sizing: border-box; /* Ensure padding is included in the width calculation */
}

.chat {
    margin: 10px 0;
    background-color: var(--background-color);
    padding: 10px;
    border-radius: 4px;
}

.user {
    background-color: var(--background-color);
    padding: 5px;
    border-radius: 4px;
}

.filebaby {
    background-color: var(--response-background-color);
    padding: 5px;
    border-radius: 4px;
}

.filebaby p {
    margin: 0;
    padding: 5px 0;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.chat-title-bar {
    background-color: var(--primary-color);
    color: white;
    padding: 10px;
    cursor: move;
    text-align: center;
    border-radius: 8px 8px 0 0;
    font-family: 'VTCMarsha-Bold';
}

/* Ensure full-width image */
.hero-img {
    width: 50%;
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 10px;
}
.notification {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: green;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    z-index: 1001; /* Ensure it's above other elements */
}

.notification.error {
    background-color: red;
}

.notification.downloading {
    background-color: blue;
}
.hero-img img {
    width: 50%;
    height: auto;
    transition: opacity 2s ease-in-out;
}

/* Media queries for responsive design */
@media (min-width: 768px) {
    .loading-indicator {
        font-size: 1.2em;
    }
    .chat-popup {
        width: 100%; /* Ensure popup matches the container width */
    }
}

.user {
    background-color: #ffffff;
    padding: 5px;
    border-radius: 4px;
}

.filebaby {
    background-color: #eefafd;
    padding: 5px;
    border-radius: 4px;
}

.filebaby p {
    margin: 0;
    padding: 5px 0;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.chat-title-bar {
    background-color: transparent;
    color: black;
    padding: 10px;
    cursor: move;
    text-align: center;
    border-radius: 8px 8px 0 0;
    font-family: 'VTCMarsha-Bold';
}

/* Media queries for responsive design */
@media (min-width: 768px) {
    .loading-indicator {
        font-size: 1.2em;
    }
    .chat-popup {
        width: 100%; /* Ensure popup matches the container width */
    }
}
